import React, {useState, useEffect} from 'react'
import {useSprings, animated as a} from 'react-spring'
import styled from 'styled-components'
import {pwindow} from '../utils'
import {Link} from 'gatsby'


const LinksWrapper = styled.div`
  font-size: 1.2rem;

  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-evenly;

  a {
    margin: 10px;
  }

  .separator {
    width: 1px;
    transform: skew(-11deg);
    height: 3rem;
    background: #222;
    margin-top: -5px;
  }
`

const Links = () => {
  return <LinksWrapper>
      <Link to="/projects/">Projects</Link>
      <div className="separator"/>
      <Link to="/wearenaice/">We are Naice</Link>
  </LinksWrapper>
}


const MobileHomeBodyWrapper = styled.div`

.images {
  position: relative;
  overflow: hidden;

}

.images > div {
  position: absolute;
  overflow: hidden;
  width: 100%;

  h3 {
    position: absolute;
    font-size: 1.5rem;
    padding: 10px 15px;
    background: white;
    opacity: 0.9;
    left: 10px;
    top: 10px;
  }
  
}


`




export default function ({projects}) {  

    const [index, setIndex] = useState(0)
    const [touchStartX, setTouchStartX] = useState(null)
    const [touchDistance, setTouchDistance] = useState(0)
          
    const height = 400
  
    const [props, set] = useSprings(projects.length, i => ({
      x: i * pwindow.innerWidth - 50,
      scale: 1.5,
      display: 'flex'
    }))
  
  const update = () => set(i => {
      if (i < index - 1 || i > index + 1) return { display: 'none' }
      const left = (i - index) * pwindow.innerWidth
      const x = i === index ? left : left - 50
      const scale = i === index ? 0.95 : 0.85
      return { x, scale, display: pwindow.innerWidth ? 'flex' : 'none' }
    })
    
  
    useEffect(() => void(update()), [index])
  
    const onTouchStart = (e) => {
      setTouchStartX(e.nativeEvent.touches[0].clientX)
    }
    
    const moveImage = () => {
    
      
      if(Math.abs(touchDistance) < 10) return
      
      touchDistance < 0 
      ? setIndex(projects.length - 1 > index ?  index + 1: index) 
      : setIndex(index === 0 ? 0 : index - 1)
      setTouchDistance(0)
    }    
      
    return <MobileHomeBodyWrapper><Links />
        <div className="images"  style={{  height }}>
            {props.map(({ display, scale, x }, i) => {
                return (
                    <a.div onTouchStart={onTouchStart} onTouchMove={(e) => setTouchDistance(e.nativeEvent.touches[0].clientX - touchStartX)} onTouchEnd={(e) => moveImage()}  key={i} style={{ display, height, left: x.interpolate(x => `${x}px`), transform: scale.interpolate((s) => `scale(${s})`)  }}>
                        <h3><Link to={`/projects/${projects[i].project_slug}`}>{`${projects[i].project_name}`}</Link></h3>
                        <div className="show_image" >
                            <img src={`${projects[i].src}`} />
                        </div>
                    </a.div>
                    )})}
        </div>
    
    </MobileHomeBodyWrapper>    
  }
  