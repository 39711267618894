import React, {useEffect, useState, useRef} from 'react'
import {graphql, Link} from 'gatsby'
import MobilHome from '../components/MobileHomeSlider'



export default (props) => {

    const {data: { allMongodbCmsCategories, allMongodbCmsProjects} } = props
    const categoiries = allMongodbCmsCategories.nodes
    const projects = allMongodbCmsProjects.nodes
      .map(({categories_uuid, project_name, project_slug, images}) => 
        ({
          project_name, 
          project_slug, 
          categories: categoiries.filter(c => categories_uuid && categories_uuid.includes(c.category_uuid)),
          src: images && images.filter(i => i.order === 1).map(i => i.uris['_600']).pop()
  
        }))
     
    return <MobilHome projects={projects} />
}



export const pageQuery = graphql`

  query {
    allMongodbCmsProjects {
      nodes {
        project_slug
        project_name
        categories_uuid
        images {
          uris {
            _600
          }
          order
        }
      }
    }
    allMongodbCmsCategories {
      nodes {
        label
        category_uuid
      }
    }
  }

`
